export const BodyFourCols = () => import('../../components/body/FourCols.vue' /* webpackChunkName: "components/body-four-cols" */).then(c => wrapFunctional(c.default || c))
export const BodyEditor = () => import('../../components/body/editor.vue' /* webpackChunkName: "components/body-editor" */).then(c => wrapFunctional(c.default || c))
export const BodyFaqlist = () => import('../../components/body/faqlist.vue' /* webpackChunkName: "components/body-faqlist" */).then(c => wrapFunctional(c.default || c))
export const BodyFaqlistItem = () => import('../../components/body/faqlistItem.vue' /* webpackChunkName: "components/body-faqlist-item" */).then(c => wrapFunctional(c.default || c))
export const BodyForm = () => import('../../components/body/form.vue' /* webpackChunkName: "components/body-form" */).then(c => wrapFunctional(c.default || c))
export const BodyImageTextCta = () => import('../../components/body/imageTextCta.vue' /* webpackChunkName: "components/body-image-text-cta" */).then(c => wrapFunctional(c.default || c))
export const BodyProductsManuals = () => import('../../components/body/productsManuals.vue' /* webpackChunkName: "components/body-products-manuals" */).then(c => wrapFunctional(c.default || c))
export const BodyProductsManualsItem = () => import('../../components/body/productsManualsItem.vue' /* webpackChunkName: "components/body-products-manuals-item" */).then(c => wrapFunctional(c.default || c))
export const BodyQuiz = () => import('../../components/body/quiz.vue' /* webpackChunkName: "components/body-quiz" */).then(c => wrapFunctional(c.default || c))
export const BodySearchBar = () => import('../../components/body/searchBar.vue' /* webpackChunkName: "components/body-search-bar" */).then(c => wrapFunctional(c.default || c))
export const BodySimpleImage = () => import('../../components/body/simpleImage.vue' /* webpackChunkName: "components/body-simple-image" */).then(c => wrapFunctional(c.default || c))
export const BodySimpleVideo = () => import('../../components/body/simpleVideo.vue' /* webpackChunkName: "components/body-simple-video" */).then(c => wrapFunctional(c.default || c))
export const BodyStoreLocator = () => import('../../components/body/storeLocator.vue' /* webpackChunkName: "components/body-store-locator" */).then(c => wrapFunctional(c.default || c))
export const BodyStoriesList = () => import('../../components/body/storiesList.vue' /* webpackChunkName: "components/body-stories-list" */).then(c => wrapFunctional(c.default || c))
export const BodyThreeCols = () => import('../../components/body/threeCols.vue' /* webpackChunkName: "components/body-three-cols" */).then(c => wrapFunctional(c.default || c))
export const BodyTitle = () => import('../../components/body/title.vue' /* webpackChunkName: "components/body-title" */).then(c => wrapFunctional(c.default || c))
export const BodyTitleComponentList = () => import('../../components/body/titleComponentList.vue' /* webpackChunkName: "components/body-title-component-list" */).then(c => wrapFunctional(c.default || c))
export const BodyTitleHorizontalSlider = () => import('../../components/body/titleHorizontalSlider.vue' /* webpackChunkName: "components/body-title-horizontal-slider" */).then(c => wrapFunctional(c.default || c))
export const BodyTitleImageTextCta = () => import('../../components/body/titleImageTextCta.vue' /* webpackChunkName: "components/body-title-image-text-cta" */).then(c => wrapFunctional(c.default || c))
export const BodyTitleSubtitleHorizontalSlider = () => import('../../components/body/titleSubtitleHorizontalSlider.vue' /* webpackChunkName: "components/body-title-subtitle-horizontal-slider" */).then(c => wrapFunctional(c.default || c))
export const BodyTitleTwoCols = () => import('../../components/body/titleTwoCols.vue' /* webpackChunkName: "components/body-title-two-cols" */).then(c => wrapFunctional(c.default || c))
export const BodyUserGuideList = () => import('../../components/body/userGuideList.vue' /* webpackChunkName: "components/body-user-guide-list" */).then(c => wrapFunctional(c.default || c))
export const CartCapSelector = () => import('../../components/cart/capSelector.vue' /* webpackChunkName: "components/cart-cap-selector" */).then(c => wrapFunctional(c.default || c))
export const CartBillingAddressPreview = () => import('../../components/cart/cartBillingAddressPreview.vue' /* webpackChunkName: "components/cart-billing-address-preview" */).then(c => wrapFunctional(c.default || c))
export const CartCoupon = () => import('../../components/cart/coupon.vue' /* webpackChunkName: "components/cart-coupon" */).then(c => wrapFunctional(c.default || c))
export const CartCouponReset = () => import('../../components/cart/couponReset.vue' /* webpackChunkName: "components/cart-coupon-reset" */).then(c => wrapFunctional(c.default || c))
export const CartItem = () => import('../../components/cart/item.vue' /* webpackChunkName: "components/cart-item" */).then(c => wrapFunctional(c.default || c))
export const CartMain = () => import('../../components/cart/main.vue' /* webpackChunkName: "components/cart-main" */).then(c => wrapFunctional(c.default || c))
export const CartPersonalInfoPreview = () => import('../../components/cart/personalInfoPreview.vue' /* webpackChunkName: "components/cart-personal-info-preview" */).then(c => wrapFunctional(c.default || c))
export const CartRelated = () => import('../../components/cart/related.vue' /* webpackChunkName: "components/cart-related" */).then(c => wrapFunctional(c.default || c))
export const CartShippingAddressPreview = () => import('../../components/cart/shippingAddressPreview.vue' /* webpackChunkName: "components/cart-shipping-address-preview" */).then(c => wrapFunctional(c.default || c))
export const HpMainSlideshow = () => import('../../components/hp/mainSlideshow.vue' /* webpackChunkName: "components/hp-main-slideshow" */).then(c => wrapFunctional(c.default || c))
export const HpMainSlideshowItem = () => import('../../components/hp/mainSlideshowItem.vue' /* webpackChunkName: "components/hp-main-slideshow-item" */).then(c => wrapFunctional(c.default || c))
export const HpMainSlideshowNew = () => import('../../components/hp/mainSlideshowNew.vue' /* webpackChunkName: "components/hp-main-slideshow-new" */).then(c => wrapFunctional(c.default || c))
export const HpMainSlideshowNewItemAsset = () => import('../../components/hp/mainSlideshowNewItemAsset.vue' /* webpackChunkName: "components/hp-main-slideshow-new-item-asset" */).then(c => wrapFunctional(c.default || c))
export const HpMainSlideshowNewItemProduct = () => import('../../components/hp/mainSlideshowNewItemProduct.vue' /* webpackChunkName: "components/hp-main-slideshow-new-item-product" */).then(c => wrapFunctional(c.default || c))
export const HpMainSlideshowNewItemSimple = () => import('../../components/hp/mainSlideshowNewItemSimple.vue' /* webpackChunkName: "components/hp-main-slideshow-new-item-simple" */).then(c => wrapFunctional(c.default || c))
export const HpProductFinder = () => import('../../components/hp/productFinder.vue' /* webpackChunkName: "components/hp-product-finder" */).then(c => wrapFunctional(c.default || c))
export const HpSliderLogos = () => import('../../components/hp/sliderLogos.vue' /* webpackChunkName: "components/hp-slider-logos" */).then(c => wrapFunctional(c.default || c))
export const HpSparePartFinder = () => import('../../components/hp/sparePartFinder.vue' /* webpackChunkName: "components/hp-spare-part-finder" */).then(c => wrapFunctional(c.default || c))
export const ProductAccessories = () => import('../../components/product/accessories.vue' /* webpackChunkName: "components/product-accessories" */).then(c => wrapFunctional(c.default || c))
export const ProductAttachments = () => import('../../components/product/attachments.vue' /* webpackChunkName: "components/product-attachments" */).then(c => wrapFunctional(c.default || c))
export const ProductBottomGallery = () => import('../../components/product/bottomGallery.vue' /* webpackChunkName: "components/product-bottom-gallery" */).then(c => wrapFunctional(c.default || c))
export const ProductBrand = () => import('../../components/product/brand.vue' /* webpackChunkName: "components/product-brand" */).then(c => wrapFunctional(c.default || c))
export const ProductDescription = () => import('../../components/product/description.vue' /* webpackChunkName: "components/product-description" */).then(c => wrapFunctional(c.default || c))
export const ProductGallery = () => import('../../components/product/gallery.vue' /* webpackChunkName: "components/product-gallery" */).then(c => wrapFunctional(c.default || c))
export const ProductGalleryMobile = () => import('../../components/product/galleryMobile.vue' /* webpackChunkName: "components/product-gallery-mobile" */).then(c => wrapFunctional(c.default || c))
export const ProductGalleryNew = () => import('../../components/product/galleryNew.vue' /* webpackChunkName: "components/product-gallery-new" */).then(c => wrapFunctional(c.default || c))
export const ProductName = () => import('../../components/product/name.vue' /* webpackChunkName: "components/product-name" */).then(c => wrapFunctional(c.default || c))
export const ProductPage = () => import('../../components/product/page.vue' /* webpackChunkName: "components/product-page" */).then(c => wrapFunctional(c.default || c))
export const ProductPeculiarities = () => import('../../components/product/peculiarities.vue' /* webpackChunkName: "components/product-peculiarities" */).then(c => wrapFunctional(c.default || c))
export const ProductRankFilter = () => import('../../components/product/rankFilter.vue' /* webpackChunkName: "components/product-rank-filter" */).then(c => wrapFunctional(c.default || c))
export const ProductRanking = () => import('../../components/product/ranking.vue' /* webpackChunkName: "components/product-ranking" */).then(c => wrapFunctional(c.default || c))
export const ProductRelated = () => import('../../components/product/related.vue' /* webpackChunkName: "components/product-related" */).then(c => wrapFunctional(c.default || c))
export const ProductReviews = () => import('../../components/product/reviews.vue' /* webpackChunkName: "components/product-reviews" */).then(c => wrapFunctional(c.default || c))
export const ProductSearch = () => import('../../components/product/search.vue' /* webpackChunkName: "components/product-search" */).then(c => wrapFunctional(c.default || c))
export const ProductSearchForm = () => import('../../components/product/searchForm.vue' /* webpackChunkName: "components/product-search-form" */).then(c => wrapFunctional(c.default || c))
export const ProductSkuSelector = () => import('../../components/product/skuSelector.vue' /* webpackChunkName: "components/product-sku-selector" */).then(c => wrapFunctional(c.default || c))
export const ProductSkuSelectorItem = () => import('../../components/product/skuSelectorItem.vue' /* webpackChunkName: "components/product-sku-selector-item" */).then(c => wrapFunctional(c.default || c))
export const ProductSpareParts = () => import('../../components/product/spareParts.vue' /* webpackChunkName: "components/product-spare-parts" */).then(c => wrapFunctional(c.default || c))
export const ProductTechnicalSheet = () => import('../../components/product/technicalSheet.vue' /* webpackChunkName: "components/product-technical-sheet" */).then(c => wrapFunctional(c.default || c))
export const ProductTechnicalSheets = () => import('../../components/product/technicalSheets.vue' /* webpackChunkName: "components/product-technical-sheets" */).then(c => wrapFunctional(c.default || c))
export const ProductThronMainImage = () => import('../../components/product/thronMainImage.vue' /* webpackChunkName: "components/product-thron-main-image" */).then(c => wrapFunctional(c.default || c))
export const ProductThronPeculiarities = () => import('../../components/product/thronPeculiarities.vue' /* webpackChunkName: "components/product-thron-peculiarities" */).then(c => wrapFunctional(c.default || c))
export const ProductUseCaseFilter = () => import('../../components/product/useCaseFilter.vue' /* webpackChunkName: "components/product-use-case-filter" */).then(c => wrapFunctional(c.default || c))
export const ProductVideo = () => import('../../components/product/video.vue' /* webpackChunkName: "components/product-video" */).then(c => wrapFunctional(c.default || c))
export const ProductWishList = () => import('../../components/product/wishList.vue' /* webpackChunkName: "components/product-wish-list" */).then(c => wrapFunctional(c.default || c))
export const ProductZoomImage = () => import('../../components/product/zoomImage.vue' /* webpackChunkName: "components/product-zoom-image" */).then(c => wrapFunctional(c.default || c))
export const SystemBrandMenu = () => import('../../components/system/brandMenu.vue' /* webpackChunkName: "components/system-brand-menu" */).then(c => wrapFunctional(c.default || c))
export const SystemCheckPassword = () => import('../../components/system/checkPassword.vue' /* webpackChunkName: "components/system-check-password" */).then(c => wrapFunctional(c.default || c))
export const SystemCompareItem = () => import('../../components/system/compareItem.vue' /* webpackChunkName: "components/system-compare-item" */).then(c => wrapFunctional(c.default || c))
export const SystemComparePreview = () => import('../../components/system/comparePreview.vue' /* webpackChunkName: "components/system-compare-preview" */).then(c => wrapFunctional(c.default || c))
export const SystemCompareProducts = () => import('../../components/system/compareProducts.vue' /* webpackChunkName: "components/system-compare-products" */).then(c => wrapFunctional(c.default || c))
export const SystemCompareProductsItem = () => import('../../components/system/compareProductsItem.vue' /* webpackChunkName: "components/system-compare-products-item" */).then(c => wrapFunctional(c.default || c))
export const SystemCta = () => import('../../components/system/cta.vue' /* webpackChunkName: "components/system-cta" */).then(c => wrapFunctional(c.default || c))
export const SystemCtaIcon = () => import('../../components/system/ctaIcon.vue' /* webpackChunkName: "components/system-cta-icon" */).then(c => wrapFunctional(c.default || c))
export const SystemCtaSmall = () => import('../../components/system/ctaSmall.vue' /* webpackChunkName: "components/system-cta-small" */).then(c => wrapFunctional(c.default || c))
export const SystemHelp = () => import('../../components/system/help.vue' /* webpackChunkName: "components/system-help" */).then(c => wrapFunctional(c.default || c))
export const SystemIcon = () => import('../../components/system/icon.vue' /* webpackChunkName: "components/system-icon" */).then(c => wrapFunctional(c.default || c))
export const SystemImg2x = () => import('../../components/system/img2x.vue' /* webpackChunkName: "components/system-img2x" */).then(c => wrapFunctional(c.default || c))
export const SystemLightSwitch = () => import('../../components/system/lightSwitch.vue' /* webpackChunkName: "components/system-light-switch" */).then(c => wrapFunctional(c.default || c))
export const SystemLoadingAnimation = () => import('../../components/system/loadingAnimation.vue' /* webpackChunkName: "components/system-loading-animation" */).then(c => wrapFunctional(c.default || c))
export const SystemMainBreadcrumbs = () => import('../../components/system/mainBreadcrumbs.vue' /* webpackChunkName: "components/system-main-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const SystemMainFooter = () => import('../../components/system/mainFooter.vue' /* webpackChunkName: "components/system-main-footer" */).then(c => wrapFunctional(c.default || c))
export const SystemMainHeader = () => import('../../components/system/mainHeader.vue' /* webpackChunkName: "components/system-main-header" */).then(c => wrapFunctional(c.default || c))
export const SystemMainHeaderSearch = () => import('../../components/system/mainHeaderSearch.vue' /* webpackChunkName: "components/system-main-header-search" */).then(c => wrapFunctional(c.default || c))
export const SystemMainLogo = () => import('../../components/system/mainLogo.vue' /* webpackChunkName: "components/system-main-logo" */).then(c => wrapFunctional(c.default || c))
export const SystemMainNewsletter = () => import('../../components/system/mainNewsletter.vue' /* webpackChunkName: "components/system-main-newsletter" */).then(c => wrapFunctional(c.default || c))
export const SystemMainTopRow = () => import('../../components/system/mainTopRow.vue' /* webpackChunkName: "components/system-main-top-row" */).then(c => wrapFunctional(c.default || c))
export const SystemMenuGroup = () => import('../../components/system/menuGroup.vue' /* webpackChunkName: "components/system-menu-group" */).then(c => wrapFunctional(c.default || c))
export const SystemMenuGroupItem = () => import('../../components/system/menuGroupItem.vue' /* webpackChunkName: "components/system-menu-group-item" */).then(c => wrapFunctional(c.default || c))
export const SystemMenuGroupItemNew = () => import('../../components/system/menuGroupItemNew.vue' /* webpackChunkName: "components/system-menu-group-item-new" */).then(c => wrapFunctional(c.default || c))
export const SystemMenuGroupNew = () => import('../../components/system/menuGroupNew.vue' /* webpackChunkName: "components/system-menu-group-new" */).then(c => wrapFunctional(c.default || c))
export const SystemModal = () => import('../../components/system/modal.vue' /* webpackChunkName: "components/system-modal" */).then(c => wrapFunctional(c.default || c))
export const SystemMouse = () => import('../../components/system/mouse.vue' /* webpackChunkName: "components/system-mouse" */).then(c => wrapFunctional(c.default || c))
export const SystemPageHead = () => import('../../components/system/pageHead.vue' /* webpackChunkName: "components/system-page-head" */).then(c => wrapFunctional(c.default || c))
export const SystemPagination = () => import('../../components/system/pagination.vue' /* webpackChunkName: "components/system-pagination" */).then(c => wrapFunctional(c.default || c))
export const SystemParsedMeta = () => import('../../components/system/parsedMeta.vue' /* webpackChunkName: "components/system-parsed-meta" */).then(c => wrapFunctional(c.default || c))
export const SystemPopup = () => import('../../components/system/popup.vue' /* webpackChunkName: "components/system-popup" */).then(c => wrapFunctional(c.default || c))
export const SystemRanking = () => import('../../components/system/ranking.vue' /* webpackChunkName: "components/system-ranking" */).then(c => wrapFunctional(c.default || c))
export const SystemSearchProductResult = () => import('../../components/system/searchProductResult.vue' /* webpackChunkName: "components/system-search-product-result" */).then(c => wrapFunctional(c.default || c))
export const SystemSimpleText = () => import('../../components/system/simpleText.vue' /* webpackChunkName: "components/system-simple-text" */).then(c => wrapFunctional(c.default || c))
export const SystemSlider = () => import('../../components/system/slider.vue' /* webpackChunkName: "components/system-slider" */).then(c => wrapFunctional(c.default || c))
export const SystemSwitch = () => import('../../components/system/switch.vue' /* webpackChunkName: "components/system-switch" */).then(c => wrapFunctional(c.default || c))
export const SystemThronPeculiarityImage = () => import('../../components/system/thronPeculiarityImage.vue' /* webpackChunkName: "components/system-thron-peculiarity-image" */).then(c => wrapFunctional(c.default || c))
export const SystemUrbanMenu = () => import('../../components/system/urbanMenu.vue' /* webpackChunkName: "components/system-urban-menu" */).then(c => wrapFunctional(c.default || c))
export const SystemUrbanMenuStep = () => import('../../components/system/urbanMenuStep.vue' /* webpackChunkName: "components/system-urban-menu-step" */).then(c => wrapFunctional(c.default || c))
export const ProductFinderChoice = () => import('../../components/productFinder/choice.vue' /* webpackChunkName: "components/product-finder-choice" */).then(c => wrapFunctional(c.default || c))
export const ProductFinderResult = () => import('../../components/productFinder/result.vue' /* webpackChunkName: "components/product-finder-result" */).then(c => wrapFunctional(c.default || c))
export const CartGatewaysCash = () => import('../../components/cart/gateways/cash.vue' /* webpackChunkName: "components/cart-gateways-cash" */).then(c => wrapFunctional(c.default || c))
export const CartGatewaysCreditCard = () => import('../../components/cart/gateways/creditCard.vue' /* webpackChunkName: "components/cart-gateways-credit-card" */).then(c => wrapFunctional(c.default || c))
export const CartGatewaysPaypal = () => import('../../components/cart/gateways/paypal.vue' /* webpackChunkName: "components/cart-gateways-paypal" */).then(c => wrapFunctional(c.default || c))
export const SystemDialogMain = () => import('../../components/system/dialog/main.vue' /* webpackChunkName: "components/system-dialog-main" */).then(c => wrapFunctional(c.default || c))
export const SystemDialogWindow = () => import('../../components/system/dialog/window.vue' /* webpackChunkName: "components/system-dialog-window" */).then(c => wrapFunctional(c.default || c))
export const SystemGothamFormField = () => import('../../components/system/gothamForm/field.vue' /* webpackChunkName: "components/system-gotham-form-field" */).then(c => wrapFunctional(c.default || c))
export const SystemGothamFormFieldCVC = () => import('../../components/system/gothamForm/fieldCVC.vue' /* webpackChunkName: "components/system-gotham-form-field-c-v-c" */).then(c => wrapFunctional(c.default || c))
export const SystemGothamFormFieldCardNumber = () => import('../../components/system/gothamForm/fieldCardNumber.vue' /* webpackChunkName: "components/system-gotham-form-field-card-number" */).then(c => wrapFunctional(c.default || c))
export const SystemGothamFormFieldDueDate = () => import('../../components/system/gothamForm/fieldDueDate.vue' /* webpackChunkName: "components/system-gotham-form-field-due-date" */).then(c => wrapFunctional(c.default || c))
export const SystemGothamFormFieldFakeSelect = () => import('../../components/system/gothamForm/fieldFakeSelect.vue' /* webpackChunkName: "components/system-gotham-form-field-fake-select" */).then(c => wrapFunctional(c.default || c))
export const SystemGothamFormFieldFile = () => import('../../components/system/gothamForm/fieldFile.vue' /* webpackChunkName: "components/system-gotham-form-field-file" */).then(c => wrapFunctional(c.default || c))
export const SystemGothamFormFieldRange = () => import('../../components/system/gothamForm/fieldRange.vue' /* webpackChunkName: "components/system-gotham-form-field-range" */).then(c => wrapFunctional(c.default || c))
export const SystemGothamFormFieldSlider = () => import('../../components/system/gothamForm/fieldSlider.vue' /* webpackChunkName: "components/system-gotham-form-field-slider" */).then(c => wrapFunctional(c.default || c))
export const SystemGothamFormFieldSliderPicker = () => import('../../components/system/gothamForm/fieldSliderPicker.vue' /* webpackChunkName: "components/system-gotham-form-field-slider-picker" */).then(c => wrapFunctional(c.default || c))
export const SystemHorizontalSliderComponent = () => import('../../components/system/horizontalSlider/component.vue' /* webpackChunkName: "components/system-horizontal-slider-component" */).then(c => wrapFunctional(c.default || c))
export const UserLoginEmailPsw = () => import('../../components/user/login/emailPsw.vue' /* webpackChunkName: "components/user-login-email-psw" */).then(c => wrapFunctional(c.default || c))
export const UserLoginEmailToken = () => import('../../components/user/login/emailToken.vue' /* webpackChunkName: "components/user-login-email-token" */).then(c => wrapFunctional(c.default || c))
export const UserLoginFacebookSSO = () => import('../../components/user/login/facebookSSO.vue' /* webpackChunkName: "components/user-login-facebook-s-s-o" */).then(c => wrapFunctional(c.default || c))
export const UserLoginGoogleSSO = () => import('../../components/user/login/googleSSO.vue' /* webpackChunkName: "components/user-login-google-s-s-o" */).then(c => wrapFunctional(c.default || c))
export const UserProfileAddressItem = () => import('../../components/user/profile/addressItem.vue' /* webpackChunkName: "components/user-profile-address-item" */).then(c => wrapFunctional(c.default || c))
export const UserProfileAddresses = () => import('../../components/user/profile/addresses.vue' /* webpackChunkName: "components/user-profile-addresses" */).then(c => wrapFunctional(c.default || c))
export const UserProfileBackground = () => import('../../components/user/profile/background.vue' /* webpackChunkName: "components/user-profile-background" */).then(c => wrapFunctional(c.default || c))
export const UserProfileCards = () => import('../../components/user/profile/cards.vue' /* webpackChunkName: "components/user-profile-cards" */).then(c => wrapFunctional(c.default || c))
export const UserProfileDetails = () => import('../../components/user/profile/details.vue' /* webpackChunkName: "components/user-profile-details" */).then(c => wrapFunctional(c.default || c))
export const UserProfileOrderItem = () => import('../../components/user/profile/orderItem.vue' /* webpackChunkName: "components/user-profile-order-item" */).then(c => wrapFunctional(c.default || c))
export const UserProfileOrderItemProduct = () => import('../../components/user/profile/orderItemProduct.vue' /* webpackChunkName: "components/user-profile-order-item-product" */).then(c => wrapFunctional(c.default || c))
export const UserProfileOrderTimeline = () => import('../../components/user/profile/orderTimeline.vue' /* webpackChunkName: "components/user-profile-order-timeline" */).then(c => wrapFunctional(c.default || c))
export const UserProfileOrders = () => import('../../components/user/profile/orders.vue' /* webpackChunkName: "components/user-profile-orders" */).then(c => wrapFunctional(c.default || c))
export const UserProfilePageHead = () => import('../../components/user/profile/pageHead.vue' /* webpackChunkName: "components/user-profile-page-head" */).then(c => wrapFunctional(c.default || c))
export const UserProfileSetPassword = () => import('../../components/user/profile/setPassword.vue' /* webpackChunkName: "components/user-profile-set-password" */).then(c => wrapFunctional(c.default || c))
export const UserRegisterEmailToken = () => import('../../components/user/register/emailToken.vue' /* webpackChunkName: "components/user-register-email-token" */).then(c => wrapFunctional(c.default || c))
export const UserRegisterFacebookSSO = () => import('../../components/user/register/facebookSSO.vue' /* webpackChunkName: "components/user-register-facebook-s-s-o" */).then(c => wrapFunctional(c.default || c))
export const UserRegisterGoogleSSO = () => import('../../components/user/register/googleSSO.vue' /* webpackChunkName: "components/user-register-google-s-s-o" */).then(c => wrapFunctional(c.default || c))
export const SystemDialogUtilsBtnCancel = () => import('../../components/system/dialog/utils/btnCancel.vue' /* webpackChunkName: "components/system-dialog-utils-btn-cancel" */).then(c => wrapFunctional(c.default || c))
export const SystemDialogUtilsBtnLoader = () => import('../../components/system/dialog/utils/btnLoader.vue' /* webpackChunkName: "components/system-dialog-utils-btn-loader" */).then(c => wrapFunctional(c.default || c))
export const SystemDialogUtilsBtnOk = () => import('../../components/system/dialog/utils/btnOk.vue' /* webpackChunkName: "components/system-dialog-utils-btn-ok" */).then(c => wrapFunctional(c.default || c))
export const SystemDialogViewsAddToCart = () => import('../../components/system/dialog/views/addToCart.vue' /* webpackChunkName: "components/system-dialog-views-add-to-cart" */).then(c => wrapFunctional(c.default || c))
export const SystemDialogViewsDefault = () => import('../../components/system/dialog/views/default.vue' /* webpackChunkName: "components/system-dialog-views-default" */).then(c => wrapFunctional(c.default || c))
export const SystemDialogViewsEditProfile = () => import('../../components/system/dialog/views/editProfile.vue' /* webpackChunkName: "components/system-dialog-views-edit-profile" */).then(c => wrapFunctional(c.default || c))
export const SystemDialogViewsManageAddress = () => import('../../components/system/dialog/views/manageAddress.vue' /* webpackChunkName: "components/system-dialog-views-manage-address" */).then(c => wrapFunctional(c.default || c))
export const SystemDialogViewsSpareParts = () => import('../../components/system/dialog/views/spareParts.vue' /* webpackChunkName: "components/system-dialog-views-spare-parts" */).then(c => wrapFunctional(c.default || c))
export const SystemDialogViewsUserLogin = () => import('../../components/system/dialog/views/userLogin.vue' /* webpackChunkName: "components/system-dialog-views-user-login" */).then(c => wrapFunctional(c.default || c))
export const SystemDialogViewsUserLoginCheckout = () => import('../../components/system/dialog/views/userLoginCheckout.vue' /* webpackChunkName: "components/system-dialog-views-user-login-checkout" */).then(c => wrapFunctional(c.default || c))
export const SystemDialogViewsUserRegister = () => import('../../components/system/dialog/views/userRegister.vue' /* webpackChunkName: "components/system-dialog-views-user-register" */).then(c => wrapFunctional(c.default || c))
export const SystemHorizontalSliderComponentsImageText = () => import('../../components/system/horizontalSlider/components/ImageText.vue' /* webpackChunkName: "components/system-horizontal-slider-components-image-text" */).then(c => wrapFunctional(c.default || c))
export const SystemHorizontalSliderComponentsBestSeller = () => import('../../components/system/horizontalSlider/components/bestSeller.vue' /* webpackChunkName: "components/system-horizontal-slider-components-best-seller" */).then(c => wrapFunctional(c.default || c))
export const SystemHorizontalSliderComponentsCategory = () => import('../../components/system/horizontalSlider/components/category.vue' /* webpackChunkName: "components/system-horizontal-slider-components-category" */).then(c => wrapFunctional(c.default || c))
export const SystemHorizontalSliderComponentsPeculiaritiesItem = () => import('../../components/system/horizontalSlider/components/peculiaritiesItem.vue' /* webpackChunkName: "components/system-horizontal-slider-components-peculiarities-item" */).then(c => wrapFunctional(c.default || c))
export const SystemHorizontalSliderComponentsReview = () => import('../../components/system/horizontalSlider/components/review.vue' /* webpackChunkName: "components/system-horizontal-slider-components-review" */).then(c => wrapFunctional(c.default || c))
export const SystemHorizontalSliderComponentsSimpleImage = () => import('../../components/system/horizontalSlider/components/simpleImage.vue' /* webpackChunkName: "components/system-horizontal-slider-components-simple-image" */).then(c => wrapFunctional(c.default || c))
export const SystemHorizontalSliderComponentsStory = () => import('../../components/system/horizontalSlider/components/story.vue' /* webpackChunkName: "components/system-horizontal-slider-components-story" */).then(c => wrapFunctional(c.default || c))
export const SystemHorizontalSliderContentBestSeller = () => import('../../components/system/horizontalSlider/content/bestSeller.vue' /* webpackChunkName: "components/system-horizontal-slider-content-best-seller" */).then(c => wrapFunctional(c.default || c))
export const SystemHorizontalSliderContentCategories = () => import('../../components/system/horizontalSlider/content/categories.vue' /* webpackChunkName: "components/system-horizontal-slider-content-categories" */).then(c => wrapFunctional(c.default || c))
export const SystemHorizontalSliderContentCustomList = () => import('../../components/system/horizontalSlider/content/customList.vue' /* webpackChunkName: "components/system-horizontal-slider-content-custom-list" */).then(c => wrapFunctional(c.default || c))
export const SystemHorizontalSliderContentGalleryThumbs = () => import('../../components/system/horizontalSlider/content/galleryThumbs.vue' /* webpackChunkName: "components/system-horizontal-slider-content-gallery-thumbs" */).then(c => wrapFunctional(c.default || c))
export const SystemHorizontalSliderContentStories = () => import('../../components/system/horizontalSlider/content/stories.vue' /* webpackChunkName: "components/system-horizontal-slider-content-stories" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
