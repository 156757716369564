import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a8042274 = () => interopDefault(import('../pages/empty.vue' /* webpackChunkName: "pages/empty" */))
const _743706d0 = () => interopDefault(import('../pages/ie-is-not-allowed-here.vue' /* webpackChunkName: "pages/ie-is-not-allowed-here" */))
const _5f01706a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6643ae49 = () => interopDefault(import('../pages/_lang/index.vue' /* webpackChunkName: "pages/_lang/index" */))
const _15996928 = () => interopDefault(import('../pages/_lang/brands/index.vue' /* webpackChunkName: "pages/_lang/brands/index" */))
const _103dfc88 = () => interopDefault(import('../pages/_lang/cart/index.vue' /* webpackChunkName: "pages/_lang/cart/index" */))
const _0bbf983c = () => interopDefault(import('../pages/_lang/checkout/index.vue' /* webpackChunkName: "pages/_lang/checkout/index" */))
const _8b43f5c8 = () => interopDefault(import('../pages/_lang/compare.vue' /* webpackChunkName: "pages/_lang/compare" */))
const _298267d8 = () => interopDefault(import('../pages/_lang/login-operatore.vue' /* webpackChunkName: "pages/_lang/login-operatore" */))
const _24ea4b04 = () => interopDefault(import('../pages/_lang/pages/index.vue' /* webpackChunkName: "pages/_lang/pages/index" */))
const _4ba3d04f = () => interopDefault(import('../pages/_lang/productfinder/index.vue' /* webpackChunkName: "pages/_lang/productfinder/index" */))
const _4da8aa80 = () => interopDefault(import('../pages/_lang/products/index.vue' /* webpackChunkName: "pages/_lang/products/index" */))
const _767f788d = () => interopDefault(import('../pages/_lang/stories/index.vue' /* webpackChunkName: "pages/_lang/stories/index" */))
const _10e0d8a7 = () => interopDefault(import('../pages/_lang/user/index.vue' /* webpackChunkName: "pages/_lang/user/index" */))
const _6ff3ecd8 = () => interopDefault(import('../pages/_lang/checkout/add-address.vue' /* webpackChunkName: "pages/_lang/checkout/add-address" */))
const _0cfc23b2 = () => interopDefault(import('../pages/_lang/checkout/billing-address.vue' /* webpackChunkName: "pages/_lang/checkout/billing-address" */))
const _f419f5d4 = () => interopDefault(import('../pages/_lang/checkout/payment.vue' /* webpackChunkName: "pages/_lang/checkout/payment" */))
const _b94788b2 = () => interopDefault(import('../pages/_lang/checkout/personal-data.vue' /* webpackChunkName: "pages/_lang/checkout/personal-data" */))
const _717bced6 = () => interopDefault(import('../pages/_lang/checkout/thankyou.vue' /* webpackChunkName: "pages/_lang/checkout/thankyou" */))
const _78cff6f6 = () => interopDefault(import('../pages/_lang/user/logout.vue' /* webpackChunkName: "pages/_lang/user/logout" */))
const _2cf62a3a = () => interopDefault(import('../pages/_lang/user/orders/index.vue' /* webpackChunkName: "pages/_lang/user/orders/index" */))
const _55855abe = () => interopDefault(import('../pages/_lang/user/profile.vue' /* webpackChunkName: "pages/_lang/user/profile" */))
const _07d2db6e = () => interopDefault(import('../pages/_lang/user/orders/_orderId.vue' /* webpackChunkName: "pages/_lang/user/orders/_orderId" */))
const _13e2c7e0 = () => interopDefault(import('../pages/_lang/brands/_slug.vue' /* webpackChunkName: "pages/_lang/brands/_slug" */))
const _28578d94 = () => interopDefault(import('../pages/_lang/pages/_slug.vue' /* webpackChunkName: "pages/_lang/pages/_slug" */))
const _80c0b8dc = () => interopDefault(import('../pages/_lang/products/_aslug/index.vue' /* webpackChunkName: "pages/_lang/products/_aslug/index" */))
const _74c8d745 = () => interopDefault(import('../pages/_lang/stories/_slug.vue' /* webpackChunkName: "pages/_lang/stories/_slug" */))
const _0d81c6fe = () => interopDefault(import('../pages/_lang/products/_aslug/_bslug/index.vue' /* webpackChunkName: "pages/_lang/products/_aslug/_bslug/index" */))
const _60dcf2f3 = () => interopDefault(import('../pages/_lang/products/_aslug/_bslug/_cslug/index.vue' /* webpackChunkName: "pages/_lang/products/_aslug/_bslug/_cslug/index" */))
const _28d03182 = () => interopDefault(import('../pages/_lang/products/_aslug/_bslug/_cslug/_dslug.vue' /* webpackChunkName: "pages/_lang/products/_aslug/_bslug/_cslug/_dslug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/empty",
    component: _a8042274,
    name: "empty"
  }, {
    path: "/ie-is-not-allowed-here",
    component: _743706d0,
    name: "ie-is-not-allowed-here"
  }, {
    path: "/",
    component: _5f01706a,
    name: "index"
  }, {
    path: "/:lang",
    component: _6643ae49,
    name: "lang"
  }, {
    path: "/:lang/brands",
    component: _15996928,
    name: "lang-brands"
  }, {
    path: "/:lang/cart",
    component: _103dfc88,
    name: "lang-cart"
  }, {
    path: "/:lang/checkout",
    component: _0bbf983c,
    name: "lang-checkout"
  }, {
    path: "/:lang/compare",
    component: _8b43f5c8,
    name: "lang-compare"
  }, {
    path: "/:lang/login-operatore",
    component: _298267d8,
    name: "lang-login-operatore"
  }, {
    path: "/:lang/pages",
    component: _24ea4b04,
    name: "lang-pages"
  }, {
    path: "/:lang/productfinder",
    component: _4ba3d04f,
    name: "lang-productfinder"
  }, {
    path: "/:lang/products",
    component: _4da8aa80,
    name: "lang-products"
  }, {
    path: "/:lang/stories",
    component: _767f788d,
    name: "lang-stories"
  }, {
    path: "/:lang/user",
    component: _10e0d8a7,
    name: "lang-user"
  }, {
    path: "/:lang/checkout/add-address",
    component: _6ff3ecd8,
    name: "lang-checkout-add-address"
  }, {
    path: "/:lang/checkout/billing-address",
    component: _0cfc23b2,
    name: "lang-checkout-billing-address"
  }, {
    path: "/:lang/checkout/payment",
    component: _f419f5d4,
    name: "lang-checkout-payment"
  }, {
    path: "/:lang/checkout/personal-data",
    component: _b94788b2,
    name: "lang-checkout-personal-data"
  }, {
    path: "/:lang/checkout/thankyou",
    component: _717bced6,
    name: "lang-checkout-thankyou"
  }, {
    path: "/:lang/user/logout",
    component: _78cff6f6,
    name: "lang-user-logout"
  }, {
    path: "/:lang/user/orders",
    component: _2cf62a3a,
    name: "lang-user-orders"
  }, {
    path: "/:lang/user/profile",
    component: _55855abe,
    name: "lang-user-profile"
  }, {
    path: "/:lang/user/orders/:orderId",
    component: _07d2db6e,
    name: "lang-user-orders-orderId"
  }, {
    path: "/:lang/brands/:slug",
    component: _13e2c7e0,
    name: "lang-brands-slug"
  }, {
    path: "/:lang/pages/:slug",
    component: _28578d94,
    name: "lang-pages-slug"
  }, {
    path: "/:lang/products/:aslug",
    component: _80c0b8dc,
    name: "lang-products-aslug"
  }, {
    path: "/:lang/stories/:slug",
    component: _74c8d745,
    name: "lang-stories-slug"
  }, {
    path: "/:lang/products/:aslug/:bslug",
    component: _0d81c6fe,
    name: "lang-products-aslug-bslug"
  }, {
    path: "/:lang/products/:aslug/:bslug/:cslug",
    component: _60dcf2f3,
    name: "lang-products-aslug-bslug-cslug"
  }, {
    path: "/:lang/products/:aslug/:bslug/:cslug/:dslug",
    component: _28d03182,
    name: "lang-products-aslug-bslug-cslug-dslug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
